import React from 'react';
import './App.css';  // You can create this file for general styling if needed
import TypewriterText from './TypewriterText';  // Import your TypewriterText component

function App() {
  return (
    <div className="App">
      <main>
        <TypewriterText />
      </main>
    </div>
  );
}

export default App;
